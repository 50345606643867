<template>
  <v-container fluid>
    <!-- Spinner -->
    <v-progress-circular
        v-if="loading"
        indeterminate
        color="primary"
      ></v-progress-circular>
    <Navbar></Navbar>
    <v-card v-if="editing">
      <v-card-title>
        <span class="headline">{{singleSessionHeadline}}</span>
      </v-card-title>
      <v-card-text>
        <v-container>
          <SingleSession :session="sessionToEdit" :newSession="newSession"></SingleSession>
        </v-container>
      </v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn color="blue darken-1" text @click="CancelEdit">Cancel</v-btn>
        <v-btn color="blue darken-1" text @click="SaveSession">Save</v-btn>
      </v-card-actions>
    </v-card>
    <v-container v-else>
      <h4 class="mt-3">Almost Done!</h4>
      <v-btn
        color="primary"
        dark
        class="mb-2 ml-2"
        @click="AddSessions"
      >Add More Sessions</v-btn>
      <v-btn
        color="primary"
        dark
        class="mb-2 mr-2 ml-2"
        @click="SaveDraftSessions"
      >Save (Draft) Sessions</v-btn>
      <v-btn
        color="primary"
        dark
        class="mb-2 mr-2"
        @click="PublishSessions"
      >Publish Sessions</v-btn>
      <v-data-table
        :headers="headers"
        :items="sessionsToCreate"
        class="elevation-1"
      >
        <template v-slot:header.name="{ header }">
          {{ header.text.toUpperCase() }}
        </template>
        <template v-slot:top>
          <v-divider
            class="mx-4"
            inset
            vertical
          ></v-divider>
          <v-spacer></v-spacer>
        </template> 
        <template v-slot:item.actions="{ item }">
          <v-icon
            small
            color="primary"
            class="mr-2 colorPrimary"
            @click="edit(item)"
          >
            mdi-pencil
          </v-icon>
          <v-icon
            small
            class="colorPrimary"
            @click="deleteSession(item)"
          >
            mdi-delete
          </v-icon>
        </template>
      </v-data-table>
    </v-container>
  </v-container>
</template>

<script>
  import SingleSession from '../CampDashboard/SingleSession.vue'
  import Navbar from '../Navbar.vue';
  import CampService from '@/api-services/camp.service.js';


  export default {
    name: 'ReviewSessions',
    components: {
      'SingleSession': SingleSession,
      "Navbar": Navbar
    }, 
    data () {
      return {
        loading: false,
        headers: [
          { text:'Start Date', value: 'startDate'},
          {text:'End Date', value: 'endDate'},
          {text:'Camp Name', value: 'campName'},
          {text:'Title', value: 'title'},
          {text:'IndexNum', value: 'indexNum', align: ' d-none'},
          { text: 'Actions', value: 'actions', sortable: false }
        ],
        editing: false,
        sessionsToCreate: [],
        sessionToEdit: {},
        newSession: false
      }
    },
   async mounted() {
      this.sessionsToCreate = await JSON.parse(localStorage.getItem('sessionsToCreate'))
      

    },
    computed: {
      localDialog: {
        get: function()  {
          return this.dialog
        },
        set: function(newVal) {
          this.$emit('updateDialog', newVal)
        }
      },
      singleSessionHeadline () {
        if(this.editing && !this.newSession){ return "Edit Session"}
        else {return "New Session"}
      }
    },
    methods:{
      CancelEdit() {
        this.editing = false
      },
      SaveSession() {
        console.log("saving Session")
        const editedSession = {...this.sessionToEdit}
        if(this.newSession) {
          console.log("adding new session")
          this.sessionToEdit.campName = this.$store.state.camp.campName
          this.sessionToEdit.campId = this.$store.state.camp.campId
          this.sessionsToCreate.push({...this.sessionToEdit})
          this.sessionToEdit = {}
          localStorage.setItem('sessionsToCreate', JSON.stringify(this.sessionsToCreate))
          this.editing = false
          this.newSession = false
        } else {
          this.sessionsToCreate.forEach(session => {
            if(session.indexNum === editedSession.indexNum) {
              session = editedSession
              this.sessionToEdit = {}
              this.editing = false
              return
            } 
          })
        }
      },
      deleteSession(item) {
        console.log("deleting", item.indexNum)
        let indexToRemove;
        for(let i = 0; i<this.sessionsToCreate.length; i++) {
          if(this.sessionsToCreate[i].indexNum === item.indexNum) {
            indexToRemove = i
          }
        }
        this.sessionsToCreate.splice(indexToRemove, 1)
        localStorage.setItem('sessionsToCreate', JSON.stringify(this.sessionsToCreate))
      },
      edit(item) {
        this.sessionToEdit = item
        this.editing = true
      },
      AddSessions() {
        this.editing = true
        this.newSession = true
      },
      async SaveDraftSessions() {
        this.loading = true; // Start the spinner
        try {
          for (const session of this.sessionsToCreate) {
            session.draft = 1;
            await this.SaveToDatabase(session);
          }
          this.$router.push("/camp");
        } finally {
          this.loading = false; // Stop the spinner
        }
      },
      async PublishSessions() {
      
        console.log("start")
          this.sessionsToCreate.forEach(async session => {
            session.draft = 0
            await this.SaveToDatabase(session)
          })
        console.log("done")
        this.$router.push("/camp")
      },
      async SaveToDatabase(session) {
        const sessionToSave = this.FormatSession(session)
        await  CampService.addNewSession(sessionToSave) 
      },
      FormatSession(session) {
        let newSession = {...session}
        newSession.price = parseInt(session.price)
        newSession.ageYoungest = parseInt(session.ageYoungest)
        newSession.ageOldest = parseInt(session.ageOldest)
        newSession.overnight = session.overnight? 1 : 0
        newSession.busService = session.busService? 1 : 0
        newSession.meals = session.meals? 1 : 0
        newSession.beforeCare = session.beforeCare? 1 : 0
        newSession.afterCare = session.afterCare? 1 : 0
        newSession.virtualCamp = session.virtualCamp? 1 : 0
        newSession.continuous = session.continuous? 1 : 0

        return newSession
      }
    },
    watch: {
      localDialog(newval) {
        if(newval === false){ this.editing = false}
      }
    }
  }
</script>
<style scoped>
.v-progress-circular {
  position: fixed;
  top: 27%;
  left: 50%;
  transform: translate(-50%, -50%);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000; /* Ensure it appears on top of other content */
}
</style>