<template>
  <div class="form">
    <v-row>
      <v-col :cols=6>
        <v-menu
          v-model="startDateMenu"
          :close-on-content-click="false"
          :nudge-right="40"
          transition="scale-transition"
          offset-y 
          max-width="290px"
          min-width="290px"
        >
          <template v-slot:activator="{ on }">
            <v-text-field
              label="Start Date"
              readonly
              :value="startDate"
              v-on="on"
            ></v-text-field>
          </template>
          <v-date-picker
            locale="en-in"
            v-model="startDate"
            no-title
            @input="startDateMenu = false"
            :min="minDate"
          ></v-date-picker>
        </v-menu>
      </v-col>
      <v-col :cols=6>
        <v-menu
          v-model="endDateMenu"
          :close-on-content-click="false"
          :nudge-right="40"
          transition="scale-transition"
          offset-y 
          max-width="290px"
          min-width="290px"
        >
          <template v-slot:activator="{ on }">
            <v-text-field
              label="End Date"
              readonly
              :value="endDate"
              v-on="on"
            ></v-text-field>
          </template>
          <v-date-picker
            locale="en-in"
            v-model="endDate"
            no-title
            @input="endDateMenu = false"
            :min="minDate"
          ></v-date-picker>
        </v-menu>
      </v-col>
    </v-row>
    <CampSessionDetails 
      :session="session"
      :formValid="formValid"
      @updateFormValid="(valid) => $emit('updateFormValid', valid)"
      @updateSessionDays="updateSessionDays"
      @updateCategories="((newVal) => {this.session.categories = newVal})"
      @updateSessionAddress="(sessionAddress) => $emit('updateSessionAddress', sessionAddress)"
    ></CampSessionDetails>
    </div>
</template>

<script>
import moment from 'moment'
import CampSessionDetails from '../CampDashboard/CampSessionDetails.vue'

export default {
  name: 'SingleSessionForm',
  components: {
      'CampSessionDetails': CampSessionDetails
  },
  props: [ 'session', 'formValid', 'newSession'],
  data () {
    return {
      minDate: moment().format('YYYY-MM-DD'),
      maxDate: moment().add('1 month').format('YYYY-MM-DD'),
      categories: [],
      startDateMenu: false,
      endDateMenu: false,
      startDate: this.session.startDate ? moment(this.session.startDate).format('YYYY-MM-DD') : moment().format('YYYY-MM-DD'),
      endDate: this.session.endDate ? moment(this.session.endDate).format('YYYY-MM-DD') : moment().add(1, 'weeks').format('YYYY-MM-DD'),
      beforeCareEndUnit: 'am',
      afterCareEndUnit: 'pm',
      camp: this.$store.state.camp,
      dayEvening: [ {name: 'am', value: 'am'},  {name: 'pm', value: 'pm'}],
      sessionDays: this.session.daysMeet,
      localSessionDays: {
        monday: false,
        tuesday: false,
        wednesday: false,
        thursday: false,
        friday: false,
        saturday: false,
        sunday: false,
      }
    }
  },
  
  methods: {
    remove(item) {
      let index = this.session.categories.findIndex(el => {return el === item})
      this.session.categories.splice(index, 1)
    },
    updateSessionDays(days) {
      this.session.daysMeet = days
    }
  },
  watch: {
    startDate(newDate) {
      this.session.startDate = newDate
    },
    endDate(newDate) {
      this.session.endDate = newDate
    },
    session(newSession) {
      this.startDate =  newSession.startDate ? moment(newSession.startDate).format('YYYY-MM-DD') : null
      this.endDate =  newSession.endDate ? moment(newSession.endDate).format('YYYY-MM-DD') : null
      this.session.endDate = newSession.endDate
      this.session.startDate = newSession.startDate
    },
  }
}
</script>

<style scoped>
.form {
  margin: 0 10%;
}

.v-icon.v-icon {
  display: block;
  color: #fff !important;
}

h1 {
  color: #ffffff;
}

</style>
